import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Alert,
  Col
} from "reactstrap";
import API from "api.js";
import { NavLink } from "react-router-dom";
import {store,persistor} from 'store/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Helmet } from "react-helmet";
import { thead, tbody } from "variables/general";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import ArrowIcon from "../assets/images/images_new/dropdown-icon.svg";
import UpgradeIcon from "../assets/images/images_new/sidebar-icons/11.svg";

class PredictionFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember:true,
      loadmore:false,
      active_subscription:[]
    };
  }
   componentDidMount() {
    API({
      method: 'GET',
      url: 'user-data-get/active_subscription'
    }).then(data => {
      let active_subscription = data.data.user.active_subscription
      this.setState({active_subscription:active_subscription});
      if(active_subscription.length ===0 || active_subscription[0].package_id === 9){
        this.setState({paidMember:false});
      }else{
        this.setState({paidMember:true});
      }
    }).catch(()=>{
    });
    this.loadTemplate();
  }
 
 loadTemplate = () =>{
  API({
      method: 'GET',
      url: 'prediction/list?skip='+this.state.data.length
      }).then(data => {
        let loadmore = true;
        if (data.data.length < 10) {
          loadmore = false;
        }
        this.setState({'data':[...this.state.data,...data.data],loadmore:loadmore});
    }).catch(()=>{
        
    });
 }

  render() {
    return (
      <>
      <Helmet htmlAttributes>
        <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content p-0 template-page">
          <DashboardHeader title="Predictions" />
          <Row className="outer-wrapper">
            <Col xs={12} className="p-0">
              <Card className="border-0">
               {!this.state.paidMember && <Alert color="success">Please upgrade your plan to access secret templates.</Alert>}
                <CardBody className="table-wrapper p-0">
                  <Table >
                    <thead className="text-primary">
                      <tr>         
                        <th className="table-counting">#</th>                 
                        <th className="">
                          Title
                        </th>
                        <th className="table-action">
                          Action
                        </th>                            
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>    
                            <td className="">
                              {val.title}
                            </td>
                            <td className="">
                              {this.state.paidMember && <NavLink
                                  to={'/admin/viewpdf/'+val.id}
                                  className="nav-link view-btn"
                                  activeClassName="active"
                                >                                        
                                  View <img className="view-btn-icon" src={ArrowIcon} alt="View" />
                                </NavLink>}
                              {!this.state.paidMember && <NavLink
                                  to={"/admin/plans"}
                                  className="nav-link view-btn upgrade-plan-btn"
                                  activeClassName="active"
                                >                                     
                                  <img className="view-btn-icon" src={UpgradeIcon} alt="View" />Upgrade Plan
                                </NavLink>}
                            </td>                                
                          </tr>
                        );
                      })}
                    {this.state.loadmore && <button className="btn" onClick={(e)=>{this.loadTemplate();}}>Load more</button>}
                    </tbody>                    
                  </Table>                   
                </CardBody>
                <div className="dashboard-pagination">
                    <ul className="dashboard-pagination-list">
                      <li><button type="button" className="pagination-list-btn prev-arrow active"><img src={ArrowIcon} alt="Arrow" /></button></li>
                      <li><button type="button" className="pagination-list-btn active-pagination">1</button></li>
                      <li><button type="button" className="pagination-list-btn">2</button></li>
                      <li><button type="button" className="pagination-list-btn">3</button></li>
                      <li><button type="button" className="pagination-list-btn">4</button></li>
                      <li><button type="button" className="pagination-list-btn next-arrow"><img src={ArrowIcon} alt="Arrow" /></button></li>
                    </ul>   
                    <div className="dashboard-pagination-dropdown">
                      <select name="pagination" id="pagination" className="pagination-dropdown">
                          <option value="">01</option>
                          <option value="">02</option>
                          <option value="">03</option>
                          <option value="">04</option>
                          <option value="">05</option>
                          <option value="">06</option>
                          <option value="">07</option>
                          <option value="">08</option>
                          <option value="">09</option>
                          <option value="">10</option>
                          <option value="">11</option>
                          <option value="">11</option>
                        </select>   
                        <span>/Page</span>               
                      </div>
                </div>    
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default PredictionFiles;
