import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  NavLink as NavLink1,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  CardText,
} from "reactstrap";
import API from "api.js";
import { NavLink, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { store } from "store/index";
import ct, {
  getAllCountries,
  getTimezonesForCountry,
} from "countries-and-timezones";
import cityTimeZones from "city-timezones";
import moment from "moment-timezone";
import { getTimeZones } from "@vvo/tzdb";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";
import TopNavPills from "components/SectionalTest/TopNavPills";
import SidebarClose from "components/Sidebar/SidebarClose";
import convertTo12Hrs from "util/convertTo12Hrs";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

class MockResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: 1,
      currentSection: "Speaking Section",
      timezone: "",
      abbrev: "UTC",
    };
  }
  componentDidMount() {
    let new_format = 0;
    if (this.props.location.pathname === "/admin/results") {
      new_format = 1;
    }

    this.getMocks(new_format);

    const timeZones = getTimeZones();

    const { country_residence, country_state } = store.getState().user;
    if (country_residence) {
      const country = Object.values(getAllCountries()).find(
        (obj) => obj.name === country_residence
      );
      if (country) {
        if (country.timezones.length === 1) {
          const timezone = country.timezones[0];
          const abbrev = timeZones.find(
            (obj) => obj.name === timezone
          ).abbreviation;
          // console.log(moment.tz(timezone).format('z'))
          this.setState({ abbrev: abbrev, timezone: timezone });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname === "/admin/results") {
        this.getMocks(1);
      } else if (this.props.location.pathname === "/admin/mock-result") {
        this.getMocks(0);
      }
    }
  }

  getMocks = (new_format) => {
    API({
      method: "GET",
      url: "mock/result?new_format=" + new_format,
    })
      .then((data) => {
        this.props.location.pathname === "/admin/results" &&
          this.toggle(1);
        this.setState({ data: data.data.result });
      })
      .catch(() => {});
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      let currentSection = "";
      switch (tab) {
        case 1:
          currentSection = "Speaking Section";
          break;
        case 2:
          currentSection = "Writing Section";
          break;
        case 3:
          currentSection = "Reading Section";
          break;
        case 4:
          currentSection = "Listening Section";
          break;
        case 5:
          // code block
          break;
        default:
        // code block
      }
      this.setState({ activeTab: tab, currentSection: currentSection });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content mockResult tabs-content p-0 mock-test">
          <DashboardHeader title="Mock Result Analysis" />
          <Row className="outer-wrapper">
            <Col xs={12}>
              <Card className="border-0 card-wrapper">
              <div className="dailytask-header">
                  <div className="dailytask-title">Mock Result Analysis</div>
                  <div className="d-flex align-items-center">                  
                    {this.state.activeTab !== 5 && (
                      <Link 
                        className="text-decoration-none"
                        to={"/admin/progress/" + this.state.activeTab + "?mock=1"}
                      >
                        <div className="score-analysis-btn">
                          Score Analysis of {this.state.currentSection}
                        </div>
                      </Link>
                    )}
                    <div>
                      {this.props.location.pathname !== "/admin/results" && (
                        <Nav
                          className="nav-pills-primary nav-pills-icons justify-content-center"
                          pills
                          role="tablist"
                          tabs
                        >
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 1 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(1);
                              }}
                            >
                              Speaking
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 2 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(2);
                              }}
                            >
                              Writing
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 3 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(3);
                              }}
                            >
                              Reading
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 4 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(4);
                              }}
                            >
                              Listening
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 5 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(5);
                              }}
                            >
                              Full Mock Test
                            </NavLink1>
                          </NavItem>
                        </Nav>
                      )}
                      {this.props.location.pathname === "/admin/results" && (
                        <TopNavPills
                          activeTab={this.state.activeTab}
                          toggle={this.toggle}
                        />
                      )}
                    </div>
                  </div>
              </div>
                <CardBody className="p-0">
                  <div className="mockResultOuter table-wrapper">
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="table-counting">#</th>
                          <th className="">Title</th>
                          <th className="">Finished at</th>
                          <th className="">Action</th>
                          <th className="">Scorecard</th>
                          <th className="">Analysis</th>
                          <th className="">Tutor Feedback</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((val, key) => {
                          var score = val.total;
                          var now = new Date();
                          var today = new Date(now.toUTCString().slice(0, -4));
                          var diffMs = today - up;
                          var up = new Date(val.updated_at);
                          let formattedUp = up
                            .toISOString()
                            .replace("T", " ")
                            .slice(0, 16);
                          if (this.state.abbrev !== "UTC") {
                            const formattedDate = new Date(
                              val.updated_at
                            ).toLocaleString("en-US", {
                              timeZone: this.state.timezone,
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour12: true,
                              hour: "numeric",
                              minute: "2-digit",
                            });

                            // Split the date and time parts
                            const [datePart, timePart] =
                              formattedDate.split(",");

                            // Split the date into month, day, and year
                            const [month, day, year] = datePart.split("/");

                            // Combine the formatted date parts
                            const formattedDateResult = `${year}-${parseInt(
                              month
                            )}-${parseInt(day)}`;

                            // Combine the formatted date and time
                            formattedUp = `${formattedDateResult}, ${timePart}`;

                            // console.log(formattedUp); // Output the final formatted date and time
                          }
                          var minutes = Math.floor(diffMs / 1000 / 60);
                          if (
                            val.mock &&
                            val.mock.category == this.state.activeTab
                          ) {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td className="">
                                  {val.mock.title ? val.mock.title : ""}
                                </td>
                                <td className="">
                                  {val.local_date ? convertTo12Hrs(val.local_date) : (
                                    (formattedUp ? formattedUp : "") + " " + this.state.abbrev
                                  )}
                                </td>
                                <td className="">
                                  {score ? (
                                    <NavLink
                                      to={"/admin/result/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="" />
                                      <div className="view-btn">View Feedback</div>
                                    </NavLink>
                                  ) : (
                                    "Please Wait..."
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                                                        to={'/admin/result/'+val.id}
                                                        className="nav-link"
                                                        activeClassName="active"
                                                      >
                                                        <i className="" />
                                                        <p>View Feedback</p>
                                                      </NavLink>:"Please Wait..."} */}
                                </td>
                                <td>
                                  {score ? (
                                    <NavLink
                                      to={"/admin/scorecard/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="" />
                                      <p>
                                        <i className="fa fa-link"></i>
                                      </p>
                                    </NavLink>
                                  ) : (
                                    "-"
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                    to={'/admin/scorecard/'+val.id}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="" />
                    <p><i className="fa fa-link"></i></p>
                  </NavLink>:"-"} */}
                                </td>
                                <td>
                                  {score ? (
                                    <NavLink
                                      to={"/admin/analysis/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="" />{" "}
                                      <p>
                                        <i className="fa fa-link"></i>
                                      </p>
                                    </NavLink>
                                  ) : (
                                    "-"
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                                                      to={'/admin/analysis/'+val.id}
                                                      className="nav-link"
                                                      activeClassName="active"
                                                    >
                                                      <i className="" />
                                                      <p><i className="fa fa-link"></i></p>
                                                    </NavLink>:""} */}
                                </td>
                                <td>
                                  {score ? (
                                    <NavLink
                                      to={"/mock-feedback/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="" />
                                      <p>
                                        <i className="fa fa-link"></i>
                                      </p>{" "}
                                    </NavLink>
                                  ) : (
                                    "-"
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                                                      to={'/mock-feedback/'+val.id}
                                                      className="nav-link"
                                                      activeClassName="active"
                                                    >
                                                      <i className="" />
                                                      <p><i className="fa fa-link"></i></p>
                                                    </NavLink>:""} */}
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default MockResult;
