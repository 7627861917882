import React from 'react';
import VideoImg from "assets/images/images_new/user-img.png";
import SearchIcon from "assets/images/images_new/zoom-lens.png";
import {
	Input
} from 'reactstrap';

export default function Filters(props) {

	const { searchByExplanationVideo, filterByVid, showData, ALL, MONTHLY_PREDICTIONS, EXAM_QUESTIONS, activeTab, search, handleSearchChange, clickSearch, type } = props;

	const VideoSearchFilter = () => {
		if (filterByVid === 'yes') {
			// todo
		}
	}

	return (
		<>
			{/* text search. */}
			<div className="searchkeywords searchkeywords-wrapper">
				<input
					className="searchkeywordsinput"
					type="text"
					name="search"
					value={search}
					onChange={handleSearchChange}
				/> 
				<button className="searchkeywords-btn">
					<img
						src={SearchIcon}
						alt="Search Image"
						onClick={clickSearch}
					/>
				</button>
			</div>

			{/* Question Strategy Videos */}
			{(type === 11 || type === 12) &&
				<div className="strategy-video" onClick={VideoSearchFilter}>
					<div className="strategy-video-img">
						<img src={VideoImg} alt="Question Strategy Video" />
					</div>
					<span>Question Strategy Video</span>
				</div>
			}

			{/* Predictions filter. */}
			<div className="tabs-content">
				<ul className="nav nav-tabs" id="myTab" role="tablist">
					<li className="nav-item" role="presentation">
						<button
							className={`nav-link ${activeTab === ALL && "active"}`}
							type="button"
							onClick={() => showData(ALL)}
						>All</button>
					</li>
					<li className="nav-item" role="presentation">
						<button
							className={`nav-link ${activeTab === MONTHLY_PREDICTIONS && "active"}`}
							type="button"
							onClick={() => showData(MONTHLY_PREDICTIONS)}
						>Monthly Prediction</button>
					</li>
					<li className="nav-item" role="presentation">
						<button
							className={`nav-link ${activeTab === EXAM_QUESTIONS && "active"}`}
							type="button"
							onClick={() => showData(EXAM_QUESTIONS)}
						>Exam Questions</button>
					</li>                        
				</ul>
			</div>
		</>
	);
}