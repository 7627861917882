import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function PlaybackSpeedBtns({
  playbackSpeed,
  changePlaybackSpeed,
}) {

  const OneX = 1;
  const OneX2 = 1.2;
  const OneX5 = 1.5;
  const speedOptions = [OneX, OneX2, OneX5];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(OneX);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSelect = (item) => {
    setSelected(item);
    changePlaybackSpeed(item);
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
        <DropdownToggle
          tag="span"
          className="badge-dropdown"
        >Speed {selected}x</DropdownToggle>
        <DropdownMenu>
          {speedOptions.map((item, i) => {
            if (item !== selected) {
              return (<DropdownItem key={i} onClick={() => onSelect(item)}>{item} x</DropdownItem>)
            }
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
