import React, { useEffect, useState } from 'react';
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";
import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

export default function QuestionListPagination(props) {

	const { total, listing, updateListingItems, page, paginationChange } = props;
	const [displaying, setDisplaying] = useState(listing);
	const [pages, setPages] = useState(0);
	const [pagesArray, setPagesArray] = useState([]);

	const ITEMS_PER_PAGE = [10, 20, 50];
	const PAGINATION_ITEMS = 5;

	useEffect(() => {
		setPages(Math.ceil(total/displaying));
	}, [total]);

	const changeDisplaying = (e) => {
		let item = parseInt(e.target.value)
		setPages(Math.ceil(total/displaying));
		setDisplaying(item);
		
		// parent file operation.
		updateListingItems(item, parseInt(page));
	}

	const handlePagination = (new_page) => {
		paginationChange(new_page);
	}

	return (
		<>
			{total > 0 &&
				<div className="dashboard-pagination">
					<Pagination
						activePage={page}
						itemsCountPerPage={displaying}
						totalItemsCount={total}
						pageRangeDisplayed={PAGINATION_ITEMS}
						onChange={handlePagination}
						hideFirstLastPages={true}
						prevPageText={<img className="prev-arrow" src={ArrowIcon} alt="Arrow" />}
						nextPageText={<img className="next-arrow" src={ArrowIcon} alt="Arrow" />}
						innerClass="dashboard-pagination-list"
						activeClass="active-pagination"
						itemClass="pagination-list-btn"
						linkClass="page-link"
					/>

					<div className="dashboard-pagination-dropdown">
						<select value={displaying} onChange={(e) => changeDisplaying(e)} id="pagination" className="pagination-dropdown" name="pagination">
							{ITEMS_PER_PAGE.map((item, i) => {
								return (<option key={i} value={item}>{item}</option>)
							})}
						</select>   
						<span>/Page</span>
					</div>
				</div>
			}
		</>
	);
}