import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col  
} from "reactstrap";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import API from '../api';
import { Redirect } from "react-router-dom";
import {userContext} from 'userContext';
import { Helmet } from "react-helmet";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { setUser, expVidPopup } from 'action/index.js';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import ArrowImg from '../assets/images/images_new/practice-arrow.svg';
import SpeakingZone from '../assets/images/images_new/microphone-icon.svg';
import WritingZone from '../assets/images/images_new/writing-icon.svg';
import ReadingZone from '../assets/images/images_new/reading-icon.svg';
import ListeningZone from '../assets/images/images_new/listening-icon.svg';
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";



const mapStateToProps = (state) => {
	return {
		user: state.user,
		expVidPopup: state.expVidPopup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => dispatch(setUser(user)),
		setExpVidPopup: (count) => dispatch(expVidPopup(count))
	};
};

class Material extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect:false,
      type:0,
      all_ok:true
    };
    this.props.history.push(this.props.match.url);
  }
  componentDidMount() {
    if(this.props.user.active_subscription.length > 0 && this.props.user.active_subscription[0].plan.is_gold === 1) {
      this.props.setExpVidPopup(0);
    }
    const fetch = async () => {
      try {
        let response = await API("/getTokens");
        if(response.status === 200) {
          // console.log(response);
        } else {
          this.notify(2 ,"Something went wrong");
        }
      } catch(e) {
      }
    }

    fetch();
  }

  showQuestion(type) {
    this.setState({'redirect':true,'type':type});
  }

  notify = (type,message) => {
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53 fa fa-bell",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    if (this.state.redirect) {
       return <Redirect to={'/admin/questionList/0/'+this.state.type} />;
    }
    return (
      <>
      <Helmet>
        <title>Top PTE Training Institute</title>
      </Helmet>
      <div className="notification-div">
        <NotificationAlert ref="notificationAlert" zIndex={9999}/>
      </div>
        {!this.state.all_ok && <ChromeCheck />}
        {this.state.all_ok && <div className="siteContent multipleCards">
            <DashboardHeader title="Practice Material" />          
            <Row>
              <Col className="practice-material-categories dashboard-content-wrapper">
              <Grid container>
                  <Grid item md={7}>
                    <Card className="speaking-zone-cards">
                      <CardHeader className="material-header">
                        <CardTitle className="material-category-title speaking" tag="h4">
                        <img className="material-header-icon" src={SpeakingZone} alt="Speaking Zone" /> Speaking Zone                      
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row className="speaking-zone-row m-0">
                          <Col className="p-0" onClick={this.showQuestion.bind(this,1)}>
                            <div className="card-content">Read Aloud <img className="card-arrow" src={ArrowImg} alt="Read Aloud" /></div>
                          </Col>
                          <Col className="p-0" onClick={this.showQuestion.bind(this,2)}>
                          <div className="card-content">Repeat Sentence <img className="card-arrow" src={ArrowImg} alt="Repeat Sentence" /></div>
                          </Col>
                          <Col className="p-0" onClick={this.showQuestion.bind(this,3)} >
                          <div className="card-content">Describe Image <img className="card-arrow" src={ArrowImg} alt="Describe Image" /></div>
                          </Col>
                        </Row>
                        <Row className="speaking-zone-row second-row m-0">
                          <Col className="p-0" onClick={this.showQuestion.bind(this,4)}>
                          <div className="card-content">Re-tell Lecture <img className="card-arrow" src={ArrowImg} alt="Re-tell Lecture" /></div>
                          </Col>
                          <Col className="p-0" onClick={this.showQuestion.bind(this,5)}>
                          <div className="card-content">Answer Short Questions <img className="card-arrow" src={ArrowImg} alt="Answer Short Questions" /></div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>  
                  </Grid>
                  <Grid item md={5}>
                    <Card className="writing-zone-card h-100">
                      <CardHeader className="material-header">
                        <CardTitle className="material-category-title writing" tag="h4">
                        <img className="material-header-icon" src={WritingZone} alt="Writing Zone" /> Writing Zone
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row className="m-0 h-100">
                          <Col className="p-0 w-100" onClick={this.showQuestion.bind(this,6)}>
                            <div className="card-content h-100">Summarize written text <img className="card-arrow" src={ArrowImg} alt="Summarize written text" /></div>
                          </Col>
                          <Col className="p-0 w-100" onClick={this.showQuestion.bind(this,7)}>
                            <div className="card-content h-100">Write Essay <img className="card-arrow" src={ArrowImg} alt="Write Essay" /></div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Grid>
                  <Grid item md={12}>
                    <Card className="reading-zone-cards">
                    <CardHeader className="material-header">
                      <CardTitle className="material-category-title reading" tag="h4"><img className="material-header-icon" src={ReadingZone} alt="Reading Zone" /> Reading Zone</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row className="reading-zone-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,8)}>
                          <div className="card-content">Multiple Type, Single Answer <img className="card-arrow" src={ArrowImg} alt="Multiple Type, Single Answer" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,9)}>
                          <div className="card-content">Multiple Type, Double Answer <img className="card-arrow" src={ArrowImg} alt="Multiple Type, Double Answer" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,10)}>
                          <div className="card-content">Reorder Paragraph <img className="card-arrow" src={ArrowImg} alt="Reorder Paragraph" /></div>
                        </Col>
                      </Row>
                      <Row className="reading-zone-row second-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,11)}>
                          <div className="card-content">Reading Fill in the Blanks <img className="card-arrow" src={ArrowImg} alt="Reading Fill in the Blanks" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,12)}>
                          <div className="card-content">Fill in the Blanks Reading & Writing <img className="card-arrow" src={ArrowImg} alt="Fill in the Blanks Reading & Writing" /></div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <Card className="listening-zone-cards">
                    <CardHeader className="material-header">
                      <CardTitle className="material-category-title listening" tag="h4"><img className="material-header-icon" src={ListeningZone} alt="Listening Zone" /> Listening Zone</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row className="listening-zone-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,13)}>
                          <div className="card-content">Summarize Spoken text <img className="card-arrow" src={ArrowImg} alt="Summarize Spoken text" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,14)}>
                          <div className="card-content">MCQ Single answer <img className="card-arrow" src={ArrowImg} alt="MCQ Single answer" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,15)}>
                          <div className="card-content">MCQ multiple answer <img className="card-arrow" src={ArrowImg} alt="MCQ multiple answer" /></div>
                        </Col>
                      </Row>
                      <Row className="listening-zone-row second-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,16)}>
                          <div className="card-content">Listening Fill In the Blanks <img className="card-arrow" src={ArrowImg} alt="Listening Fill In the Blanks" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,17)}>
                          <div className="card-content">Highlight correct summary <img className="card-arrow" src={ArrowImg} alt="Highlight correct summary" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,18)}>
                          <div className="card-content">Select Missing Word <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" /></div>
                        </Col>
                      </Row>
                      <Row className="listening-zone-row third-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,19)}>
                          <div className="card-content">Highlight Incorrect word <img className="card-arrow" src={ArrowImg} alt="Highlight Incorrect word" /></div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,20)}>
                          <div className="card-content">Write from Dictation <img className="card-arrow" src={ArrowImg} alt="Write from Dictation" /></div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  </Grid>
              </Grid>
              </Col>
            </Row>
          </div>}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Material);
