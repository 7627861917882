import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";
import TagIcon1 from "assets/images/images_new/tag1.svg";
import TagIcon2 from "assets/images/images_new/tag2.svg";
import TagIcon3 from "assets/images/images_new/tag3.svg";
import TagIconDefault from "assets/images/images_new/tagDefault.png";

export default function ListTable(props) {

	const { page, listing, data, type, activeTab, mark, attempted, complexity, filterByVid, search } = props;
	// let { page } = props;
	let question_number = ((page * listing) - listing) + 1;
	const [list, setList] = useState([]);
	
	useEffect(() => {

		let items = [];
		data.map((prop, key) => {
			let item = {
				q_no: question_number,
				is_new: newQuestionIcon(prop.created_at),
				title: (type !== '2' && type !== '5' && prop.q_title),
				tag: applyTag(prop.tag),
				complexity: {
					class_name: getComplexity("class", prop.complexity),
					label: getComplexity("label", prop.complexity)
				},
				link_url: linkUrl(prop.id, question_number)
			}
			items.push(item);
			question_number += 1;
		});
		setList(items);
	}, [data]);

	const newQuestionIcon = (created_at) => {
		const pastTime = new Date(created_at);
		const now = new Date();
		const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
		const timeDiffInMs = now.getTime() - pastTime.getTime();

		return (timeDiffInMs >= thirtyDaysInMs) ? false : true;
	}

	const linkUrl = (q_id, question_number) => {
		return `/admin/practice/0/${type}/${q_id}/${activeTab}/${mark}/${attempted}/${complexity}/${filterByVid}?page=${page}&search=${search}&random=${Math.floor(Math.random() * 1000000000)}&item_number=${question_number}`;
	}

	const applyTag = (tag) => {
		let question_tag = TagIconDefault;
		
		if (tag.length > 0) {
			switch (tag[0].tag) {
				case "red":
					question_tag = TagIcon2;
					break;
				case "green":
					question_tag = TagIcon1;
					break;
				case "yellow":
					question_tag = TagIcon3;
					break;
				default:
				break;
			}
		}
		return question_tag;
	}

	const getComplexity = (req_type, comp) => {
		let complexity = "";
		switch(comp) {
			case 0:
				complexity = req_type === "label" ? "Easy" : "easy";
				break;
			case 1:
				complexity = req_type === "label" ? "Medium" : "medium";
				break;
			case 2:
				complexity = req_type === "label" ? "Difficult" : "difficult";
				break;
		}
		return complexity;
	}

	return (
		<table>
			<thead>
				<tr>
					<th>#</th>
					<th>Title</th>
					<th>Badge</th>
					<th>Complexity</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				{list.map((item, key) => {
					return (
						<tr key={key}>
							<td>{question_number++}</td>
							<td>
								{item.is_new && <img src="/images/new-question-tag.png" className='new-question-image'/>}
								{item.title}
							</td>
							<td>
								<img src={item.tag} alt="Badge" />
							</td>
							<td>
								<span
									className={`complexity-badge ${item.complexity.class_name}`}
								>{item.complexity.label}</span>
							</td>
							<td>
								<NavLink
									to={item.link_url}
									className="nav-link"
									activeClassName="active"
								>
									<span className="view-btn">
										View <img className="view-btn-icon" src={ArrowIcon} alt="Badge" />
									</span>
								</NavLink>
							</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	);
}