import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

export default function FillInTheBlankExplanation({isOpen,closeModal,title,data,keyName=""}) {

	const [modal, setModal] = useState(isOpen);
	const modalData = data?data[keyName.toLowerCase().trim()]:"";

	const closeModalIntarnal = () => {
		setModal(false);
		// close modal in parent file.
		setTimeout(() => {
			closeModal();
		}, 500);
	}
	// const chosenWordBlanks = ['none','','No word was selected','No word was selected.']

	return (
    <Modal
      isOpen={modal}
      size="lg"
      backdropClassName="shadow-backdrop"
      className="practice-shadowing-modal text-translate-modal word-definition-modal pre-line"
    >
      <ModalHeader className="word-definition-heading" tag="div">
        <h4 className="mb-2 text-light">Explanation [{title}]</h4>
      </ModalHeader>
      <ModalBody>
        <div className="text-translation">
          <div className="mb-2 definition-text">
            {keyName==='paragraph_explanation'?(data): modalData ? (
              <>
                <b>Selected Answer:</b>{" "}
                <i style={{ color: "#ff6160", fontWeight: "600" }}>{(modalData.chosen_word==='' || modalData.chosen_word==='__')?"No word was selected":modalData.chosen_word} </i>
                <br />
                {(modalData.chosen_word === "No word was selected" || modalData.chosen_word==='' || modalData.chosen_word==='__') ? (
                  ""
                ) : (
                  <>
                    <b>Meaning:</b> {modalData.chosen_word_meaning} <br />
                    <b>Definition:</b> {modalData.chosen_word_defination} <br />
                    <b>Example:</b> {modalData.chosen_word_example} <br />
                  </>
                )}
                <hr />
                <b>Correct Answer:</b>{" "}
                <i style={{ color: "#23b67f", fontWeight: "600" }}>{modalData.correct_word}</i>{" "}
                <br />
                <b>Meaning:</b> {modalData.correct_word_meaning} <br />
                <b>Definition:</b> {modalData.correct_word_defination} <br />
                <b>Example:</b> {modalData.correct_word_example} <br />
                <hr />
                <b>Explanation:</b> {modalData.explanation} <br />
              </>
            ) : (
              "Loading..."
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          className="close-button"
          onClick={() => {
            closeModalIntarnal();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}