import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,Button,
  TabContent, TabPane, Nav, NavItem,CardText,NavLink as NavLink1
} from "reactstrap";
import {Bar } from "react-chartjs-2";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { NavLink } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { connect } from 'react-redux';
import { thead, tbody } from "variables/general";
import {store,persistor} from 'store/index';
//import { Chart } from 'react-charts'
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import InfoIcon from "../assets/images/images_new/info-circle.png";
import ArrowIcon from "../assets/images/images_new/dropdown-icon.svg";

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};
function MyChart(props) {
  let arrr = [];
   props.data.forEach((v,k)=>{
            arrr.push({x:k+1,y:v});
  });

   console.log(arrr)
  const data = React.useMemo(
      () => [
        {
          label: 'Series 1',
          data: arrr
        }
      ],
      []
    )
   
    const axes = React.useMemo(
      () => [
        { primary: true, type: 'linear', position: 'bottom' },
        { type: 'linear', position: 'left' }
      ],
      []
    )

  return (
      <div
        style={{

          height: '300px'
        }}
      >
        <h6 className="big-title">
           Sectional score graph
        </h6>
        
      </div>
    )
}

class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extra: [],
      data: [],
      graph:[],
      activeTab:0,
      all_ok1:true,
      all_ok:true,
      mock:0,
      count:[100,100,100,100,100],
      subscriptionId:null,
      isSubscribedCheck:(store.getState().subscribed ? store.getState().subscribed : 'false'),
      
    };
  }

  getQuestionType = (type) =>{
    let title = "";
    switch(type) {
      case 1:
        title = "Read Aloud";
        break;
      case 2:
        title = "Repeat Sentence"
        break;
      case 3:
        title = "Describe Image";
        break;
      case 4:
        title = "Re-tell Lecture";
        break;
      case 5:
        title = "Answer Short Questions";
        break;
      case 6:
        title = "Summarize written text";
        break;
      case 7:
        title = "Write Essay";
        break;
      case 8:
        title = "Multiple Type, Single Answer ";
        break;
      case 9:
        title = "Multiple Type, Double Answer ";
        break;
      case 10:
        title = "Reorder Paragraph ";
        break;
      case 11:
        title = "Reading Fill in the Blanks ";
        break;
      case 12:
        title = "Fill in the Blanks Reading & Writing ";
        break;
      case 13:
        title = "Summarize Spoken text ";
        break;
      case 14:
        title = "MCQ Single answer ";
        break;
      case 15:
        title = "MCQ multiple answer ";
        break;
      case 16:
        title = "Listening Fill In the Blanks ";
        break;
      case 17:
        title = "Highlight correct summary ";
        break;
      case 18:
        title = "Select Missing Word ";
        break;
      case 19:
        title = "Highlight Incorrect word ";
        break;
      case 20:
        title = "Write from Dictation ";
        break;
      default:
        // code block
    }
    return title;
  }

  getMockType = () =>{
    let title = "";
    switch(this.props.match.params.id) {
      case '1':
        title = "Speaking";
        break;
      case '2':
        title = "Writing"
        break;
      case '3':
        title = "Reading";
        break;
      case '4':
        title = "Listening";
        break;
      case 5:
        title = "Full";
        break;
      
      default:
        // code block
    }
    return title;
  }
   componentDidMount() {
    
      let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
      let all_ok1 = (isChrome)?true:false;
      
      navigator.getUserMedia({ audio: true },
      () => {                   
        console.log('Permission Granted');
        this.setState({all_ok1:all_ok1}); 
      },
      () => {
        this.setState({all_ok1:false});
        console.log('Permission Denied');
        
      },
      );
      let urlParams = new URLSearchParams(window.location.search);
      let myParam = urlParams.get('mock');
      let mock = 0;
      if (parseInt(myParam) == 1) {
        mock = 1;
      }else{
        mock = 0
      }
      
    console.log(this.state.isSubscribedCheck);
    API({
        method: 'GET',
        url: 'progress/'+this.props.match.params.id+'?mock='+mock,
      }).then(data => {
        console.log(data.data.data)

        this.setState({data:data.data.data,graph:data.data.graph});
    }).catch(()=>{
        
      });

  }

  getScore = (key)=>{
    let score = 0;
    let from = 0;
    this.state.data[key].map((q)=>{
      q.score.map((w)=>{
          score += w.score;
          from += w.from;
      })
    });
    return from > 0 ? Math.round(score*100/from) : 0;
  }

  toggle = tab => {
    let all_ok = true;
    if(this.state.activeTab !== tab){
      
      this.setState({activeTab:tab},function(){
        if ([1,5].includes(this.state.activeTab)) {
         all_ok = this.state.all_ok1;
        }else{
          all_ok = true;
        }
        this.setState({all_ok:all_ok});
      });
    }
  }
 
  render() {
    let i = 0;
    const chartExample4 = {
  data: {
    labels: this.state.graph.map((v,k)=>{
      return 'mock '+(k+1);
    }),
    datasets: [
      {
        label: "Score",
        borderColor: "#fcc468",
        fill: true,
        backgroundColor: "#fcc468",
        hoverBorderColor: "#fcc468",
        borderWidth: 8,
        barPercentage: 0.4,
        data: this.state.graph,
      }
    ],
  },
  options: {
    tooltips: {
      tooltipFillColor: "rgba(0,0,0,0.5)",
      tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      tooltipFontSize: 14,
      tooltipFontStyle: "normal",
      tooltipFontColor: "#fff",
      tooltipTitleFontFamily:
        "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      tooltipTitleFontSize: 14,
      tooltipTitleFontStyle: "bold",
      tooltipTitleFontColor: "#fff",
      tooltipYPadding: 6,
      tooltipXPadding: 6,
      tooltipCaretSize: 8,
      tooltipCornerRadius: 6,
      tooltipXOffset: 10,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 20,
          },
          gridLines: {
            zeroLineColor: "transparent",
            display: true,
            drawBorder: false,
            color: "#9f9f9f",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            zeroLineColor: "white",
            display: false,

            drawBorder: false,
            color: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
            fontStyle: "bold",
          },
        },
      ],
    },
  },
};
    return (
      <>
        {true && <div className="content p-0 tabs-content test-result">
          <DashboardHeader title="Mock Tests" />   
          <Row className="outer-wrapper">
            <Col xs={12} className="p-0">
              <Card className="border-0 card-wrapper">
                <div className="dailytask-title">Test Result</div>
                <CardHeader className="p-0 m-0">
                  <CardTitle className="p-0 m-0" tag="h4">Progress Tracker</CardTitle>                 
                  {this.state.graph.length === 0 ? <CardText className="text-data-warning"><img src={InfoIcon} alt="Image" />{this.state.isSubscribedCheck === true ? ' The data below is calculated on the basis of last 3 months performace.' : ' The data below is calculated on the basis of last 1 months performace.'}</CardText> : ''}
                </CardHeader>
                <CardBody className="p-0">
                  <div className="progressTrackerOuter">
                  {!this.state.all_ok && <div className="mt-5"><ChromeCheck /></div>}
                  {this.state.all_ok && 
                  <div className="table-wrapper">
                    <Table >
                        <thead className="text-primary">
                          <tr>                                  
                            <th className="table-counting">#</th>
                            <th className="">
                              Question Type
                            </th>
                            <th className="">
                              Question Attempted
                            </th>                                    
                            <th className="">
                              Score Percentage
                            </th>                                      
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(this.state.data).map((val, _key) => {
                            let array = this.state.data[val];
                            var result = array.reduce( (acc, o) => (acc[o.question_id] = (acc[o.question_id] || 0)+1, acc), {} );
                            let count = 0;
                            for(let _keyy in result) {
                              ++count;
                            }
                            // console.log(result)
                            // console.log(count);
                              return (
                                    <tr>
                                      <td>{_key + 1}</td> 
                                      <td className="">
                                        {this.getQuestionType(parseInt(val))}
                                      </td>
                                      <td>{count}</td>
                                      <td className="">
                                        {this.getScore(parseInt(val)) +"%"}
                                      </td>
                                    </tr>
                                  );                        
                      
                          })}
                        </tbody>
                    </Table>                    
                  </div>
                  }
                    <div className="dashboard-pagination">
                      <ul className="dashboard-pagination-list">
                        <li><button type="button" className="pagination-list-btn prev-arrow active"><img src={ArrowIcon} alt="Arrow" /></button></li>
                        <li><button type="button" className="pagination-list-btn active-pagination">1</button></li>
                        <li><button type="button" className="pagination-list-btn">2</button></li>
                        <li><button type="button" className="pagination-list-btn">3</button></li>
                        <li><button type="button" className="pagination-list-btn">4</button></li>
                        <li><button type="button" className="pagination-list-btn next-arrow"><img src={ArrowIcon} alt="Arrow" /></button></li>
                      </ul>   
                      <div className="dashboard-pagination-dropdown">
                        <select name="pagination" id="pagination" className="pagination-dropdown">
                            <option value="">01</option>
                            <option value="">02</option>
                            <option value="">03</option>
                            <option value="">04</option>
                            <option value="">05</option>
                            <option value="">06</option>
                            <option value="">07</option>
                            <option value="">08</option>
                            <option value="">09</option>
                            <option value="">10</option>
                            <option value="">11</option>
                            <option value="">11</option>
                          </select>   
                          <span>/Page</span>               
                        </div>
                  </div>    
                    </div>                           
                    {this.state.graph.length > 0 && 
                  <Card className="shadow-none">
                    <CardHeader className="text-center pl-0">
                    <div className="dailytask-title text-center">{this.getMockType()} Mock Score</div>
                      {/* <CardTitle tag="h4">{this.getMockType()} Mock Score</CardTitle> */}
                      <p className="card-category"></p>
                    </CardHeader>
                    <CardBody className="p-0">
                      <Bar
                        data={chartExample4.data}
                        options={chartExample4.options}
                      />
                    </CardBody>
                  </Card>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
