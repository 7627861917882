import React from "react";
import AvatarImg from '../../../assets/images/images_new/avatar.png';
import Bell from "components/Notification/Bell";
import CollapseIcon from "../../../assets/images/images_new/dropdown-icon.svg"


// export default function DashboardHeader() {
  export default function DashboardHeader({ title }) {
  const openCloseSidebar = () => {
		document.body.classList.toggle('leftSidebarClosed');
	}
  const openCloseSidebarMobile = () => {
		// document.body.classList.remove('leftSidebarClosed');
		if (document.body.classList.contains('leftSidebarClosed')) {
			document.body.classList.remove('openSidebar');
		} else {
			document.body.classList.add('openSidebar');
		}
	}
  return (
    <>
    <div className="dasboard-head">
      <div className="d-flex align-items-center">
        <span className="toggleIcon closeSidebar" onClick={() => openCloseSidebar()}>
          <img src={CollapseIcon} alt="collapse icon" />
        </span>
        <span className="toggleIcon openSidebarMobile align-self-center pr-3" onClick={() => openCloseSidebarMobile()}>
				  <img src="/images/new_dash/hamburger.png" alt="collapse icon" />
			  </span>
        <h2 className="title">{title}</h2>
      </div>
      <div className="user-info-content">
      <div className="user-notification">
      <Bell />
      </div>
      <div className="user-info">
          <div className="user-img">
          <img src={AvatarImg} alt="image" />
          </div>
          <div className="user-dropdown-wrapper">                    
          <select name="user" id="user" className="user-dropdown">
              <option value="user1">Olivia Rhye</option>
              <option value="user2">Olivia Rhy</option>
              <option value="user3">Olivia Rhee</option>
          </select>                  
          </div>
      </div>
      </div>
    </div>
    </>
  );
}

