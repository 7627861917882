import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import Speech from 'speak-tts';
import API from 'api.js';
import SelectOption from "components/PracticeMaterial/SelectOption";
import multiLing from "assets/images/multi-ling.png";

export default function WordDefinitionModal(props) {
	const { isOpen, toggle, word, languages} = props;

	const DEFAULT_DEFINITION = [{
		definition: "Fetching definition(s)..."
	}];
	const DEFINITION_ERROR = [{
		definition: "Unable to fetch definition(s). Please try again later."
	}];
	const [definitions, setDefinitions] = useState(DEFAULT_DEFINITION);
	const [definitionIsLoaded, setLoaded] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("English");
	const [wordDisplay, setWordDisplay] = useState(word);
	const [speech, setSpeech] = useState(null);

	
	useEffect(() => {
		if (word !== "") {
			setDefinitions(DEFAULT_DEFINITION);
			setLoaded(false);
			API({
				url: "word/definition",
				method: "POST",
				data: { word, lang: selectedLanguage }
			}).then((res) => {
				if (res.status === 200) {
					const { word_definitions,word } = res.data.data;
					setLoaded(true);
					setDefinitions(word_definitions);
					setWordDisplay(word);
				} else {
					setDefinitions(DEFINITION_ERROR);
				}
			}).catch((err) => {
				setDefinitions(DEFINITION_ERROR);
			});
		}
	}, [word,selectedLanguage]);

	useEffect(() => {
		const speechInstance = new Speech();
		speechInstance
		  .init({
			volume: 1,
			lang: mapLanguageCode(selectedLanguage),
			rate: 1,
			pitch: 1,
			voice: 'Google US English',
			splitSentences: false,
		  })
		  .then((data) => {
			const v = data.voices.filter((voice) => {
				return data.lang === voice.lang;
			});
			if (v.length > 0) {
				speechInstance.setVoice(v[0].name);
			}
			setSpeech(speechInstance);
		  })
		  .catch((e) => {
			console.error("An error occurred while initializing speech:", e);
		  });
	}, [selectedLanguage]);

	const selectScriptLanguage = (selectedValue) => {
		if (selectedValue !== "English" && selectedValue !== "0") {
			setSelectedLanguage(selectedValue);
		} else {
			setSelectedLanguage("English");
		}
	};

	const mapLanguageCode = (language) => {
		const languageMap = {
			English: 'en-US',
			Hindi: 'hi-IN',
			French: 'fr-FR',
			German: 'de-DE',
			Urdu: 'ur-PK',       
			Vietnamese: 'vi-VN', 
			Spanish: 'es-ES',
			French: 'fr-FR',
			Urdu: 'ur-PK',
			Arabic: 'ar-SA',    
			Nepali: 'ne-NP',   
			Tamil: 'ta-IN',     
			Telugu: 'te-IN',  
		};
		return languageMap[language] || 'en-US'; 
	  };

	const pronunciation =async(word) => {
			if (speech) {
			speech.speak({
			  text: word,
			  queue: false,
			  listeners: {
				// onstart: () => {
				//   console.log("Started speaking");
				// },
				// onend: () => {
				//   console.log("Finished speaking");
				// },
				// onerror: (e) => {
				//   console.error("Error during speech:", e);
				// },
			  },
			});
		  }
	}

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			backdropClassName="shadow-backdrop"
			className="practice-shadowing-modal text-translate-modal word-definition-modal"
		>
			<ModalHeader
				className="word-definition-heading"
				tag="div"
			>
				<div className="row">
					<div className="col-md-7">
						<h4 className="mb-2 text-light">
							{wordDisplay}
							{selectedLanguage !== 'Urdu' && selectedLanguage !== 'Arabic' && (
							<i
								onClick={() => pronunciation(wordDisplay)}
								className="fa fa-volume-up"
								aria-hidden="true"
							></i>
						)}
						</h4>
						<h6 className="mb-2 text-light float-start">
							Translate :</h6>
						<div className="row " style={{ textAlign: 'left' }}>
							{languages.length > 0 && (
								<SelectOption
									isClearable={false}
									selected={selectedLanguage}
									placeholder={selectedLanguage}
									data={languages.filter(language => language.label !== selectedLanguage)}
									changeEvent={selectScriptLanguage}
								/>
							)}
						</div>
					</div>
					
					<div className="col-md-3">&nbsp;</div>
					<div className="col-md-2">
						<img src={multiLing} alt="multi-languages" />
					</div>
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="text-translation">
					<div className="mb-2 definition-text">
						{definitionIsLoaded ? (
							<>
								{definitions?.map((definition, i) => {
									return (
										<div 
										  key={i} 
										  className={`word-definition ${(selectedLanguage === 'Urdu' || selectedLanguage === 'Arabic') ? 'urdu-alignment' : ''}`}>
											{definition.definition}
										</div>
									  );
									  
								})}
							</>
						) : (
							<ul>
								{definitions?.map((definition, i) => {
									return (<li key={i}>{definition.definition}</li>)
								})}
							</ul>
						)}
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color="danger"
					className="close-button"
					onClick={() => toggle(false)}
				>Close</Button>
			</ModalFooter>
		</Modal>
	);
}