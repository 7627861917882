import React, { Component } from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import API from "api.js";
import SidebarClose from "components/Sidebar/SidebarClose";
import Introduction from "components/StgyVideos/Introduction";
import Category from "components/StgyVideos/Category";
import { store } from "store/index";
import { setAlert } from "action";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import SpeakingZone from "../assets/images/images_new/microphone-icon.svg";
import WritingZone from "../assets/images/images_new/writing-icon.svg";
import ReadingZone from "../assets/images/images_new/reading-icon.svg";
import ListeningZone from "../assets/images/images_new/listening-icon.svg";
import VideoIcon from "../assets/images/images_new/video-icon.svg";
import UserIcon from "../assets/images/images_new/user-icon.svg";
import ArrowIcon from "../assets/images/images_new/dropdown-icon.svg"



const classes = [
  "",
  "speakingVideos",
  "writingVideos",
  "readingVideos",
  "listeningVideos",
];

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: false,
      modal1: false,
      videoPlan: true,
      loadmore: false,
      active_subscription: [],
    };
  }

  render() {
    return (
      <>
        <div className="content p-0">
            <DashboardHeader title="Secret Strategy video" />
            <div className="strategyVideosCntnt outer-wrapper">
              <div className="intro-video-section">
                <h2 className="intro-title">Introduction to video course</h2>
                <div className="intro-video-row">              
                  <div className="intro-video-wrapper">
                  <iframe
                    className="intro-video"
                    src="https://www.youtube.com/embed/3YoE2J085NI"
                    title="YouTube Live video player"
                    frameborder="0"
                  ></iframe>                                                
                  </div>
                  <div className="intro-video-content">
                    <h3>PTE Academic full video course</h3>
                    <p>by <strong>Varun Dhawan</strong></p>
                  </div>
                </div>             
              </div>
              <div className="strategyideo-section">
                  <h2 className="strategy-video-header"><img src={SpeakingZone} alt="image" /> Speaking Zone</h2>
                  <div className="strategy-video-row">
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="strategyideo-section">
                  <h2 className="strategy-video-header"><img src={WritingZone} alt="image" /> Writing Zone</h2>
                  <div className="strategy-video-row">
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="strategyideo-section">
                  <h2 className="strategy-video-header"><img src={ReadingZone} alt="image" /> Reading Zone</h2>
                  <div className="strategy-video-row">
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="strategyideo-section">
                  <h2 className="strategy-video-header"><img src={ListeningZone} alt="image" /> Listening Zone</h2>
                  <div className="strategy-video-row">
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                    <div className="strategy-video-card">
                      <div className="strategy-video-wrapper">
                        <iframe
                        className="strategy-video"
                        src="https://www.youtube.com/embed/3YoE2J085NI"
                        title="YouTube Live video player"
                        frameborder="0"
                      ></iframe> 
                      </div>
                      <div className="strategy-video-content">
                        <h2 className="title">Introduction</h2>
                        <p className="sub-title">How I Scored 90 for the 6th time</p>
                        <p className="timing">12 Mins</p>
                      </div>
                    </div>
                  </div>
              </div>  
              <div className="final-tips-wrapper">
                <div className="final-tips-title">Final Tips</div>
                <p className="final-tips-description">More than 107 students got their desired score with LA in the last month. This Final Tips could help you</p>
                <button className="final-tips-btn"><img src={VideoIcon} alt="Image" /> Final Tips for Exam</button>
              </div>          
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="videos-comment-page outer-wrapper">
              <div className="video-comment-card">
                <div className="video-comment-wrapper">
                  <iframe
                    className="comment-video"
                    src="https://www.youtube.com/embed/3YoE2J085NI"
                    title="YouTube Live video player"
                    frameborder="0"
                  >
                  </iframe>   
                </div>  
                <div className="video-comment-content">
                  <h2>PTE SPEAKING MASTERCLASS WITH UPDATED STRATEGIES</h2>
                  <p>PTE Speaking Masterclass with Updated Strategies</p>
                </div>            
              </div>
              <div className="videos-comment-content">
                  <input type="text" class="form-control" placeholder="Add a comment..." />
                  <button type="button" className="comment-btn">Comment</button>
                  <div className="comment-content">
                    <h2 className="comment-title">Comments <a href="#" className="comment-number">(10 of 120)</a></h2>
                    <div className="comment-info">
                      <div className="user-info">
                        <div className="user-row">
                          <div className="user-img">
                            <img src={UserIcon} alt="Image" />
                          </div>
                          <div className="user-name">Santosh</div>
                          <div className="comment-date">2024-07-04 03:04(AEDT)</div>
                        </div>
                        <p className="user-comment">can you please make videos on new questions added to the speaking, please?</p>
                      </div>
                      <div className="user-info">
                        <div className="user-row">
                          <div className="user-img">
                            <img src={UserIcon} alt="Image" />
                          </div>
                          <div className="user-name">Santosh</div>
                          <div className="comment-date">2024-07-04 03:04(AEDT)</div>
                        </div>
                        <p className="user-comment">can you please make videos on new questions added to the speaking, please?</p>
                      </div>
                      <div className="user-info">
                        <div className="user-row">
                          <div className="user-img">
                            <img src={UserIcon} alt="Image" />
                          </div>
                          <div className="user-name">Santosh</div>
                          <div className="comment-date">2024-07-04 03:04(AEDT)</div>
                        </div>
                        <p className="user-comment">can you please make videos on new questions added to the speaking, please?</p>
                      </div>
                      <div className="user-info">
                        <div className="user-row">
                          <div className="user-img">
                            <img src={UserIcon} alt="Image" />
                          </div>
                          <div className="user-name">Santosh</div>
                          <div className="comment-date">2024-07-04 03:04(AEDT)</div>
                        </div>
                        <p className="user-comment">can you please make videos on new questions added to the speaking, please?</p>
                      </div>
                      <div className="user-info">
                        <div className="user-row">
                          <div className="user-img">
                            <img src={UserIcon} alt="Image" />
                          </div>
                          <div className="user-name">Santosh</div>
                          <div className="comment-date">2024-07-04 03:04(AEDT)</div>
                        </div>
                        <p className="user-comment">can you please make videos on new questions added to the speaking, please?</p>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-pagination">
                    <ul className="dashboard-pagination-list">
                      <li><button type="button" className="pagination-list-btn prev-arrow active"><img src={ArrowIcon} alt="Arrow" /></button></li>
                      <li><button type="button" className="pagination-list-btn active-pagination">1</button></li>
                      <li><button type="button" className="pagination-list-btn">2</button></li>
                      <li><button type="button" className="pagination-list-btn">3</button></li>
                      <li><button type="button" className="pagination-list-btn">4</button></li>
                      <li><button type="button" className="pagination-list-btn next-arrow"><img src={ArrowIcon} alt="Arrow" /></button></li>
                    </ul>   
                    <div className="dashboard-pagination-dropdown">
                      <select name="pagination" id="pagination" className="pagination-dropdown">
                          <option value="">01</option>
                          <option value="">02</option>
                          <option value="">03</option>
                          <option value="">04</option>
                          <option value="">05</option>
                          <option value="">06</option>
                          <option value="">07</option>
                          <option value="">08</option>
                          <option value="">09</option>
                          <option value="">10</option>
                          <option value="">11</option>
                          <option value="">11</option>
                        </select>   
                        <span>/Page</span>               
                    </div>
              </div>
              </div>
             
            </div>
          </div>
      </>
    );
  }
}

export default Video;
