import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserType } from "action";

import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import hiImg from "../assets/images/images_new/hi-icon.png";
import calendarImg from "../assets/images/images_new/calendar-icon.svg";
import ClockImg from "../assets/images/images_new/clock-icon.svg";
import SpeakingZone from "../assets/images/images_new/microphone-icon.svg";
import WritingZone from "../assets/images/images_new/writing-icon.svg";
import ReadingZone from "../assets/images/images_new/reading-icon.svg";
import ListeningZone from "../assets/images/images_new/listening-icon.svg";
import ArrowImg from "../assets/images/images_new/practice-arrow.svg";
// import CarRegistrationChart from "../components/Dashboard/DashboardChart";

export default function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [estimatedScore, setEstimatedScore] = useState(null);
  const [practiceData, setPracticeData] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(300);
  const [totalProgress, setTotalProgress] = useState(466);

  const dispatch = useDispatch();

  useEffect(() => {
    // Mock loading data from an API
    setTimeout(() => setLoading(false), 300);

    // Dispatching user type for example purposes
    dispatch(setUserType("free"));

    // Clean up body class on unmount
    return () => {
      document.body.classList.remove("dashboardPage");
    };
  }, [dispatch]);

  // Calculate percentage dynamically
  const percentage = Math.round((currentProgress / totalProgress) * 100);


  return (
    <>
      {!loading && (
        <div className="siteContent p-0">
          <DashboardHeader title="Dashboard" />
          <div className="dashboard-wrapper">
            <div className="row">
              <div className="col-md-5 pr-md-0">
                <div className="booknow-card">
                  <h2>
                    <img src={hiImg} alt="Hello" /> Good Afternoon, Olivia
                  </h2>
                  <div className="suggestion-text">
                    <span>Suggestion</span>
                    More than 107 students got their desired score with LA in
                    the last month. Book your FREE Trial Class NOW.
                  </div>
                  <a href="#" className="booknow-btn">
                    <img src={calendarImg} alt="Book Now" /> Book Now
                  </a>
                </div>
              </div>
              <div className="col-md-7 pl-md-0">
                <div className="progress-section">
                  <div className="progress-card speaking">
                    <h4>
                      <img src={SpeakingZone} alt="Speaking" /> Speaking
                    </h4>
                    <div className="progress-container">
                      <div className="progress-title">
                        <div className="progress-percentage">{percentage}%</div>
                        <div className="progress-text">
                          {currentProgress} <span>of {totalProgress}</span>
                        </div>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-card writing">
                    <h4>
                      <img src={WritingZone} alt="Writing" /> Writing
                    </h4>
                    <div className="progress-container">
                      <div className="progress-title">
                        <div className="progress-percentage">{percentage}%</div>
                        <div className="progress-text">
                          {currentProgress} <span>of {totalProgress}</span>
                        </div>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-card reading">
                    <h4>
                      <img src={ReadingZone} alt="Reading" /> Reading
                    </h4>
                    <div className="progress-container">
                      <div className="progress-title">
                        <div className="progress-percentage">{percentage}%</div>
                        <div className="progress-text">
                          {currentProgress} <span>of {totalProgress}</span>
                        </div>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-card listening">
                    <h4>
                      <img src={ListeningZone} alt="Listening" /> Listening
                    </h4>
                    <div className="progress-container">
                      <div className="progress-title">
                        <div className="progress-percentage">{percentage}%</div>
                        <div className="progress-text">
                          {currentProgress} <span>of {totalProgress}</span>
                        </div>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 pr-md-0">
                <div className="dashboard-card">
                  <h2 className="title">My Stats</h2>
                  <div className="stats-row">
                    <div className="stats-cards">
                      <div className="stats-card total-mocks">
                        <div className="count">0</div>
                        <p>
                          Total Mocks
                          <br />
                          Practiced
                        </p>
                      </div>
                      <div className="stats-card desired-score">
                        <div className="d-flex align-items-center">
                          <div className="count">1</div>
                          <p>
                            Desired
                            <br />
                            Score
                          </p>
                        </div>
                        <div className="arrow-icon">
                          <img src={ArrowImg} alt="Image" />
                        </div>
                      </div>
                      <div className="stats-card questions-practiced">
                        <div className="count">2</div>
                        <p>
                          Questions
                          <br />
                          Practiced
                        </p>
                      </div>
                      <div className="stats-card days-practiced">
                        <div className="d-flex align-items-center">
                          <div className="count">2</div>
                          <p>
                            Days
                            <br />
                            Practiced
                          </p>
                        </div>
                        <div className="arrow-icon">
                          <img src={ArrowImg} alt="Image" />
                        </div>
                      </div>
                    </div>
                    <div className="exam-date-card">
                      <div className="exam-date-content">
                        <div className="stats-card days-practiced">
                          <div className="d-flex align-items-center">
                            <div className="count">
                              0 <span>Days Left</span>
                            </div>
                            <p>
                              Exam
                              <br />
                              Date
                            </p>
                          </div>
                          <div className="arrow-icon">
                            <img src={ArrowImg} alt="Image" />
                          </div>
                        </div>
                      </div>
                      <div className="stats-btns">
                        <a href="#" className="stats-btn">
                          <img src={calendarImg} alt="Image" /> Monthly
                          Predictions
                        </a>
                        <a href="#" className="stats-btn">
                          <img src={ClockImg} alt="Image" /> Daily Feedback
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pr-md-0">
                <div className="dashboard-card dashboard-score-card">
                  <h2 className="title">Estimated Exam Score</h2>
                  <div className="estimated-card-content">
                    <ul className="estimated-list">
                      <li>
                        <h4>
                          <img src={SpeakingZone} alt="Imgage" /> 46
                        </h4>
                        <span>11%</span>
                      </li>
                      <li>
                        <h4>
                          <img src={WritingZone} alt="Imgage" />
                          65
                        </h4>
                        <span>24%</span>
                      </li>
                      <li>
                        <h4>
                          <img src={ReadingZone} alt="Imgage" />
                          68
                        </h4>
                        <span>26%</span>
                      </li>
                      <li>
                        <h4>
                          <img src={ListeningZone} alt="Imgage" />
                          72
                        </h4>
                        <span>39%</span>
                      </li>
                    </ul>
                    <div>
					          {/* < CarRegistrationChart /> */}
					          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores error doloremque soluta, </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="dashboard-videos-section">
                <div className="dashboard-videos-header">
                  <h2 className="title">PTE Tutorial Videos</h2>
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="speaking-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#speaking"
                        type="button"
                        role="tab"
                        aria-controls="speaking"
                        aria-selected="true"
                      >
                        Speaking
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="writing-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#writing"
                        type="button"
                        role="tab"
                        aria-controls="writing"
                        aria-selected="false"
                      >
                        Writing
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="reading-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#reading"
                        type="button"
                        role="tab"
                        aria-controls="reading"
                        aria-selected="false"
                      >
                        Reading
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="listening-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#listening"
                        type="button"
                        role="tab"
                        aria-controls="listening"
                        aria-selected="false"
                      >
                        Listening
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="others-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#others"
                        type="button"
                        role="tab"
                        aria-controls="others"
                        aria-selected="false"
                      >
                        Others
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="speaking"
                    role="tabpanel"
                    aria-labelledby="speaking-tab"
                  >
                    <div className="dashboard-videos-row">
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="writing"
                    role="tabpanel"
                    aria-labelledby="writing-tab"
                  >
                    <div className="dashboard-videos-row">
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="reading"
                    role="tabpanel"
                    aria-labelledby="reading-tab"
                  >
                    <div className="dashboard-videos-row">
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="listening"
                    role="tabpanel"
                    aria-labelledby="listening-tab"
                  >
                    <div className="dashboard-videos-row">
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="others"
                    role="tabpanel"
                    aria-labelledby="others-tab"
                  >
                    <div className="dashboard-videos-row">
                      <div
                        className="video-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <div className="video-wrapper">
                          <iframe
                            className="dashboard-video"
                            src="https://www.youtube.com/embed/3YoE2J085NI"
                            title="YouTube Live video player"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <div className="dashboard-video-content">
                          <h3 className="video-title">
                            How I Scored 90 for the 6th time
                          </h3>
                          <p className="video-sub-title">12 Mins</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade dashboard-video-popup"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    New tips and strategies
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <iframe
                    className="dashboard-popup-video"
                    src="https://www.youtube.com/embed/3YoE2J085NI"
                    title="YouTube Live video player"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


