import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Alert,
  Col,
  Nav,
  NavItem,
  FormGroup,
  Form,
  Input,
  NavLink as NavLinkRS,
} from "reactstrap";

import API from "api.js";
import { NavLink } from "react-router-dom";
import { store, persistor } from "store/index";
import { Helmet } from "react-helmet";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { thead, tbody } from "variables/general";
import ContactUsPremium from "components/Help/ContactUsPremium";
import { connect } from "react-redux";
import SidebarClose from "components/Sidebar/SidebarClose";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import ArrowIcon from "../assets/images/images_new/dropdown-icon.svg"

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember: true,
      loadmore: false,
      activeTab: 1,
    };
  }
  componentDidMount() {
    this.loadTemplate();
  }

  loadTemplate = () => {
    API({
      method: "GET",
      url: "help?skip=" + this.state.data.length + "&type=1",
    })
      .then((data) => {
        let loadmore = true;
        if (data.data.length < 10) {
          loadmore = false;
        }
        this.setState({
          data: [...this.state.data, ...data.data],
          loadmore: loadmore,
        });
      })
      .catch(() => {});
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content p-0">
          <DashboardHeader title="Need Help" />
          <div className="need-help-page outer-wrapper">
            <Row>
              <Col xs={12}>
                <Card>
                  {!this.state.paidMember && (
                    <Alert color="success">
                      Please upgrade your plan to access secret templates.
                    </Alert>
                  )}
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="silver-tab" data-bs-toggle="tab" data-bs-target="#silver-tab-pane" type="button" role="tab" aria-controls="silver-tab-pane" aria-selected="true">Help</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="gold-tab" data-bs-toggle="tab" data-bs-target="#gold-tab-pane" type="button" role="tab" aria-controls="gold-tab-pane" aria-selected="false">Contact Us</button>
                      </li>
                    </ul>
                  <div className="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="silver-tab-pane" role="tabpanel" aria-labelledby="silver-tab" tabindex="0">
                      <CardBody className="table-wrapper p-0">
                    {this.state.activeTab === 1 && (
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th className="table-counting">#</th>
                            <th className="table-questiong">Question</th>
                            <th className="table-resolution">Resolution</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td className="">{val.title}</td>
                                <td className="">
                                  <NavLink
                                    to={"/admin/viewpdf/" + val.id}
                                    className="nav-link view-btn"
                                    activeClassName="active"
                                  >
                                    {/* <i className="" /> */}
                                    View
                                    <img className="view-btn-icon" src={ArrowIcon} alt="Image" />
                                  </NavLink>
                                </td>
                              </tr>
                            );
                          })}
                          {this.state.loadmore && (
                            <button
                              className="btn"
                              onClick={(e) => {
                                this.loadTemplate();
                              }}
                            >
                              Load more
                            </button>
                          )}
                        </tbody>                        
                      </Table>                          
                    )}
                    {this.state.activeTab === 2 && <ContactUsPremium />}
                    <div className="dashboard-pagination">
                          <ul className="dashboard-pagination-list">
                            <li><button type="button" className="pagination-list-btn prev-arrow active"><img src={ArrowIcon} alt="Arrow" /></button></li>
                            <li><button type="button" className="pagination-list-btn active-pagination">1</button></li>
                            <li><button type="button" className="pagination-list-btn">2</button></li>
                            <li><button type="button" className="pagination-list-btn">3</button></li>
                            <li><button type="button" className="pagination-list-btn">4</button></li>
                            <li><button type="button" className="pagination-list-btn next-arrow"><img src={ArrowIcon} alt="Arrow" /></button></li>
                          </ul>   
                          <div className="dashboard-pagination-dropdown">
                            <select name="pagination" id="pagination" className="pagination-dropdown">
                                <option value="">01</option>
                                <option value="">02</option>
                                <option value="">03</option>
                                <option value="">04</option>
                                <option value="">05</option>
                                <option value="">06</option>
                                <option value="">07</option>
                                <option value="">08</option>
                                <option value="">09</option>
                                <option value="">10</option>
                                <option value="">11</option>
                                <option value="">11</option>
                              </select>   
                              <span>/Page</span>               
                          </div>
                </div>
                  </CardBody>  
                      </div>    
                      <div class="tab-pane fade" id="gold-tab-pane" role="tabpanel" aria-labelledby="gold-tab" tabindex="0">
                        <div className="help-form">
                          <Form>
                            <FormGroup>
                              <label>Subject</label>
                              <Input
                                name="name"
                                placeholder="write a subject"
                                type="text"
                              />
                            </FormGroup>
                            <FormGroup>
                              <label>Message</label>
                              <Input
                                name="message"
                                as="textarea"
                                rows={3}
                                placeholder="Enter your message"
                                type="text"
                              />
                            </FormGroup>
                            <Row className="contact-form-btns">
                                <Col className="text-md-right" md="12">
                                  <Button
                                    className="contact-discard-btn"                 
                                  >
                                    Discard
                                  </Button>
                                  <Button
                                    className="btn-round contact-save-btn"
                                    color="info"
                                  >
                                    Send
                                  </Button>
                                </Col> 
                            </Row>
                          </Form>
                        </div>
                      </div> 
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Launch demo modal
            </button>
            <div class="modal fade view-pdf-popup" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <h1 class="modal-title" id="exampleModalLabel">I am getting a blank screen/Website is not working properly
                    </h1>
                  </div>
                  <div class="modal-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo sed cumque corrupti nihil reiciendis dolore, rem perferendis eos excepturi. Exercitationem error voluptatum, mollitia minima distinctio rerum vitae incidunt! Atque, nostrum.
                  </div>                  
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(Template);
